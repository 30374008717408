import React, {useEffect, useState} from "react";
import {View, Text, StyleSheet, PixelRatio, Image, Linking} from "react-native";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import TopMenu from "../components/public/TopMenu.tsx";
import logo from '../assets/images/logo_big.svg'
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";
import HelpPopup from "../components/public/HelpPopup.tsx";
import BottomView from "../components/public/BottomView.tsx";
import {helpText} from '../components/public/plogging/HelpText.tsx'
import popupModal from "../components/public/PopupModal.tsx";
import {addRefreshToken, addToken, client} from "../api/client.ts";
import {useMutation} from "@tanstack/react-query";
import {urls} from "../api/Url.ts";
import {responses, responseWrapper} from "../api/Entity.ts";
import * as moment from "moment";
import AsyncStorage from "@react-native-async-storage/async-storage";

function Main({navigation, route}){

    const [isLogin, setIsLogin] = useState(false)
    const [name, setName] = useState('')
    const [locationVisible, setLocationVisible] = useState(false)
    const [gpsVisible, setGpsVisible] = useState(false)
    const [closedPlogging, setClosedPlogging] = useState(false)
    const [nextVisible, setNextVisible] = useState(false)
    const [helpVisible, setHelpVisible] = useState(false)
    const [isDesktop, setIsDesktop] = useState(false)

    useEffect(() => {
        const agent = navigator.userAgent.toLowerCase()

        if(agent.indexOf('iphone') === -1 && agent.indexOf('android') === -1){
            //setIsDesktop(true)
        }

        AsyncStorage.getItem('token').then((v)=>{
            if (v !== null) {
                addToken(v)
                AsyncStorage.getItem('refreshToken').then((refreshToken=>{
                    addRefreshToken(refreshToken)
                }))
                setIsLogin(true)
            }
        })


    }, []);


    useEffect(() => {
        if (client.defaults.headers.common['Authorization'] === undefined) {
            setIsLogin(false)
        }else{
            setIsLogin(true)
        }
    }, [client.defaults.headers.common['Authorization']]);

    const userInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<responses['whoami']>>(urls['whoami']),
        onSuccess: ({data})=>{
            if(data.status) {
                setName(data.data.name)
            }else {
                if(data.errorcode === 901) {
                    setClosedPlogging(true)
                }else {
                    userInstance.mutate()
                }
            }
        },
    })

    useEffect(() => {
        if(isLogin) {
            userInstance.mutate()
        }
    }, [isLogin]);



    return (
        <View style={styles.wrapper}>
            <PopupModal
                visible={locationVisible}
                setVisible={setLocationVisible}
                text={'<화성호> 지역에서만\n 사용할 수 있습니다.'}
                type={PopupType.alert}
                buttonText={'종료하기'}
            />
            <PopupModal
                visible={gpsVisible}
                setVisible={setGpsVisible}
                text={'GPS 및 카메라 이용에\n동의해 주세요. 미 동의시\n서비스 이용이 불가능합니다.'}
                type={PopupType.default}
                iconType={IconType.gps}
            />
            <PopupModal
                visible={closedPlogging}
                setVisible={setClosedPlogging}
                text={'봉사자님의 안전을 위해\n밤11시 ~ 새벽 4시\n' +
                    '사이에는 활동이 불가합니다.\n해가 떠있는 시간에\n이용해주세요.'}
                type={PopupType.alert}
                buttonText={'종료하기'}
            />
            <PopupModal
                visible={nextVisible}
                setVisible={setNextVisible}
                text={'00월 00일 00시 00분\n이후 다시 참여 가능합니다.'}
                type={PopupType.alert}
                buttonText={'종료하기'}
            />

            <PopupModal visible={isDesktop} setVisible={setIsDesktop} type={PopupType.alert}
                        text={'<화성호> 지역에서\n모바일로 사용 가능합니다.'} buttonText={'종료하기'}
                        action={()=>{
                            navigation.push('main')
                            setIsDesktop(false)
                        }}
            />

            <HelpPopup visible={helpVisible} setVisible={setHelpVisible} subject={helpText}
                       action={()=>{
                           if (isLogin)
                               navigation.push('plogging')
                           else
                               navigation.push('login')
                           setHelpVisible(false)
                       }}
            />
            <TopMenu navigation={navigation} route={route}/>
            <View style={styles.contentWrapper}>
                <View style={styles.logoWrapper}>
                    <Image source={logo} style={styles.logo} resizeMode={'stretch'}/>
                </View>

                <View style={styles.middleWrapper}>
                    {isLogin? (
                            <Text style={styles.middleText}>
                                <Text style={{fontWeight:800}}>{name}</Text>
                                {` 님, 반갑습니다.`}
                            </Text>
                        ):
                        (<Text style={styles.middleText}>
                            {'봉사자님, 우측 상단 버튼을 눌러\n'}
                            <Text style={styles.middleInnerText}>{'로그인 해주세요!'}</Text>
                        </Text>)
                    }
                </View>

                <View style={styles.buttonContainer}>
                    <MainButton text={'플로깅 방법'} action={()=>{
                        setHelpVisible(true)
                    }} type={ButtonType.default}/>
                    <MainButton text={'플로깅 시작하기'} action={()=>{
                        if (isLogin)
                            navigation.push('plogging')
                        else
                            navigation.push('login')
                    }} type={ButtonType.default}/>
                    <MainButton text={'나의 기록 보기'} action={()=>{
                        if (isLogin)
                            navigation.push('myPlogging')
                        else
                            navigation.push('login')
                    }} type={ButtonType.default}/>
                    <MainButton
                        text={'화성시 자원봉사센터 인스타그램'}
                        action={()=>{
                            //navigation.push('eventPage')
                            Linking.openURL('https://www.instagram.com/hscity1365/').then()
                        }}
                        type={ButtonType.other}
                    />
                </View>
            </View>

            <BottomView />
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        backgroundColor: '#FEFAED',
    },
    contentWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(35),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40),
        gap: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    logoWrapper: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(55),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(90),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(105),
    },
    logo: {
      width: PixelRatio.getPixelSizeForLayoutSize(180),
      height: PixelRatio.getPixelSizeForLayoutSize(196),
    },
    horizontalWrapper: {
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'row',
        gap: 20,
    },
    middleWrapper: {
        width: '100%',
        padding: PixelRatio.getPixelSizeForLayoutSize(25),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        borderColor: '#B2B2B2',
        alignItems: 'center',
        justifyContent: 'center',
    },
    middleText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '400',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
        color: '#666565',
        textAlign: 'center'
    },
    middleInnerText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '800',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
        color: '#DD7451',
        textAlign: 'center'
    },
    buttonContainer: {
        width:'100%',
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    }
})

export default Main