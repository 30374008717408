import {PixelRatio, StyleSheet, Text, View} from "react-native";
import arrow_ from '../assets/images/arrow_right.svg'
import TopMenu from "../components/public/TopMenu.tsx";
import InputLine from "../components/acount/InputLine.tsx";
import CheckBox from "../components/acount/CheckBox.tsx";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import {useEffect, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {client} from "../api/client.ts";
import {requests, responses, responseWrapper} from "../api/Entity";
import {urls} from "../api/Url.ts";
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";

function EditMyInfo({navigation, route}){
    const [isPasswordCheck, setIsPasswordCheck] = useState(false)
    const [isTelCheck, setIsTelCheck] = useState(false)

    const [id, setId] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [birth, setBirth] = useState('')

    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        if(password === passwordCheck) {
            setIsChecked(true)
        }else {
            setIsChecked(false)
        }
    }, [password,passwordCheck]);

    const userInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<responses['whoami']>>(urls['whoami']),
        onSuccess: ({data})=>{
            if(data.status) {
                setId(data.data.id)
                setName(data.data.name)
                setBirth(data.data.birthday)
                setPhone(data.data.tel)
            }else {

            }
        }
    })

    useEffect(() => {
        userInstance.mutate()
    }, []);

    const updateInstance = useMutation({
        mutationFn: (req: requests['update'])=>client.post<responseWrapper<responses['signup']>>(urls['update'],req),
        onSuccess: ({data})=>{
            if (data.status) {
                navigation.pop(1)
            }else {
                if (data.errorcode===103)
                    setIsTelCheck(true)
                else if (data.errorcode===102)
                    setIsPasswordCheck(true)
            }
        }
    })


    return (
        <View style={styles.wrapper}>
            <PopupModal visible={isPasswordCheck} setVisible={setIsPasswordCheck} text={'비밀번호를 확인해주세요'}/>
            <PopupModal visible={isTelCheck} setVisible={setIsTelCheck} type={PopupType.default}
                        iconType={IconType.check} text={'이미 사용 중인\n전화번호입니다.'}/>

            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.titleWrapper}>
                <Text style={styles.titleText}>{'내 정보 수정하기'}</Text>
            </View>
            <View style={{marginTop: PixelRatio.getPixelSizeForLayoutSize(30)}}>
                <InputLine subject={''} text={id} setText={setId} addStar={false} useSubject={false} hint={'아이디_'} isUse={false}/>
                <View style={{marginTop: PixelRatio.getPixelSizeForLayoutSize(26)}}>
                    <InputLine subject={''} text={oldPassword} setText={setOldPassword} addStar={false} hint={'현재 비밀번호'} isSecurity={true} useSubject={false}/>
                    <InputLine subject={''} text={password} setText={setPassword} addStar={false} hint={'변경할 비밀번호'} isSecurity={true} useSubject={false}/>
                    <InputLine subject={''} text={passwordCheck} setText={setPasswordCheck} addStar={true} hint={'비밀번호 확인'} isSecurity={true} useSubject={false}/>
                </View>
                <View style={styles.checkPasswordWrapper}>
                    {password!=='' && <Text style={[styles.checkPasswordText, !isChecked&&styles.failedCheck]}>
                        비밀번호가 {isChecked?'일치합니다':'일치하지않습니다'}.
                    </Text>}
                </View>
            </View>

            <View style={styles.otherWrapper}>
                <InputLine subject={'이름'} text={name} setText={setName} addStar={false} hint={'홍길동_'} isUse={false} />
                <InputLine subject={'연락처'} text={phone} setText={setPhone} addStar={false} isPhone={true}/>
                <InputLine subject={'생년월일'} text={birth} setText={setBirth} addStar={false}
                           hint={'YYYYMMDD'} isBirth={true} isUse={false}/>
            </View>
            <MainButton text={'이렇게 변경하기'} type={ButtonType.default} action={()=>{
                if (isChecked) {
                    const req: requests['update'] = {
                        tel: phone,
                        password: oldPassword,
                        newpassword: password
                    }

                    updateInstance.mutate(req)
                }

            }}/>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#FEFAED',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40),
    },
    titleWrapper: {
        width: '100%',
        alignItems: 'center',
    },
    titleText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        color: '#469046',
        textAlign: 'center'
    },
    separator_top: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#B2B2B2',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(40),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(45)
    },
    checkPasswordWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(3),
        height: PixelRatio.getPixelSizeForLayoutSize(23)
    },
    checkPasswordText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        color: '#3C7FB3',
        lineHeight: '160%',
    },
    failedCheck: {
        color: 'red',
    },
    otherWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(15),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(40)
    },
    arrow: {
        width: PixelRatio.getPixelSizeForLayoutSize(11),
        height: PixelRatio.getPixelSizeForLayoutSize(7),
    }
})

export default EditMyInfo;