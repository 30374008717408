import {PixelRatio, StyleSheet, Text, View} from "react-native";
import TopMenu from "../components/public/TopMenu.tsx";
import {useEffect, useState} from "react";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import HelpView from "../components/public/HelpView.tsx";
import {useMutation} from "@tanstack/react-query";
import {client} from "../api/client.ts";
import {responses, responseWrapper} from "../api/Entity.ts";
import {urls} from "../api/Url.ts";

function MyInfo({navigation, route}){

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [birth, setBirth] = useState('')

    const userInstance = useMutation({
        mutationFn: ()=>client.post<responseWrapper<responses['whoami']>>(urls['whoami']),
        onSuccess: ({data})=>{
            if(data.status) {
                setId(data.data.id)
                setName(data.data.name)
                setBirth(data.data.birthday)
                setPhone(data.data.tel)
            }
        }
    })

    useEffect(() => {
        userInstance.mutate()
    }, []);

    
    return(
        <View style={styles.wrapper}>
            <TopMenu navigation={navigation} route={route} userVisible={true}/>
            <Text style={styles.title}>내 정보</Text>
            <View style={styles.contentWrapper}>
                <View style={styles.horizontalWrapper}>
                    <Text style={styles.contentTitle}>아이디</Text>
                    <Text style={styles.contentSubject}>{id}</Text>
                </View>
                <View style={styles.horizontalWrapper}>
                    <Text style={styles.contentTitle}>이름</Text>
                    <Text style={styles.contentSubject}>{name}</Text>
                </View>
                <View style={styles.horizontalWrapper}>
                    <Text style={styles.contentTitle}>연락처</Text>
                    <Text style={styles.contentSubject}>{phone}</Text>
                </View>
                <View style={styles.horizontalWrapper}>
                    <Text style={styles.contentTitle}>생년월일</Text>
                    <Text style={styles.contentSubject}>{birth}</Text>
                </View>
            </View>
            <HelpView marginTop={39}/>
            <View>
                <MainButton text={'정보수정하기'} type={ButtonType.default} action={()=>navigation.push('editMyInfo')}/>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        height: '100vh',
        backgroundColor: '#FEFAED',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(20),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40),
    },
    title: {
        color: '#469046',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
        textAlign: 'center'
    },
    contentWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(30),
        gap: PixelRatio.getPixelSizeForLayoutSize(1)
    },
    horizontalWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(40),
        flexDirection: 'row',
    },
    contentTitle: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(40),
        flex: 0.3,
        alignSelf: 'center',
        textAlign: 'center',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#B2B2B2',
        backgroundColor: '#D9D9D9'
    },
    contentSubject: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(40),
        flex: 0.7,
        alignSelf: 'center',
        paddingLeft: PixelRatio.getPixelSizeForLayoutSize(13),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#B2B2B2',
    },

})

export default MyInfo;