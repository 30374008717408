import {View, StyleSheet, PixelRatio, Text, FlatList} from "react-native";
import {useEffect, useState} from "react";
import TopMenu from "../components/public/TopMenu.tsx";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import {useMutation} from "@tanstack/react-query";
import {client} from "../api/client.ts";
import {urls} from "../api/Url.ts";
import {responses, responseWrapper} from "../api/Entity.ts";
import * as moment from "moment";


function MyPlogging({navigation, route}){

    const [name, setName] = useState('')
    const [list, setList] = useState([])

    const userInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<responses['whoami']>>(urls['whoami']),
        onSuccess: ({data})=>{
            if(data.status) {
                setName(data.data.name)
            }
        }
    })

    const listInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<[responses['plogging_list']]>>(urls['plogging_list']),
        onSuccess: ({data})=>{
            if(data.status) {
                setList(data.data)
            }
        }
    })

    useEffect(() => {
        userInstance.mutate()
        listInstance.mutate()
    }, []);


    
    return (
        <View style={styles.wrapper}>
            <TopMenu navigation={navigation} route={route}/>
            <View style={styles.titleWrapper}>
                <Text style={styles.titleText}>{name} 님의 플로깅 일지</Text>
            </View>
            <View style={styles.contentWrapper}>

                {
                    list.length > 0 && (
                        <>
                            <View style={[styles.tableRow, {backgroundColor: '#469046',}]}>
                                <View style={[styles.tableItem,{flex: 0.7} ]}>
                                    <Text style={styles.tableTitle}>봉사일자</Text>
                                </View>
                                <View style={[styles.tableItem,{flex: 0.3}]}>
                                    <Text style={styles.tableTitle}>스탬프기록</Text>
                                </View>
                            </View>
                            <FlatList
                                data={list}
                                renderItem={({item})=>{
                                    return (
                                        <View style={styles.tableRow}>
                                            <View style={[styles.tableItem,{flex: 0.7} ]}>
                                                <Text style={styles.tableText}>{moment(item.regDate).format('YYYY/MM/DD')}</Text>
                                            </View>
                                            <View style={[styles.tableItem,{flex: 0.3}]}>
                                                <Text style={styles.tableText}>{item.pointList?.length}</Text>
                                            </View>
                                        </View>
                                    )
                                }}/>
                        </>
                    )
                }
                {
                    list.length === 0 && (
                        <View style={styles.emptyWrapper}>
                            <Text style={styles.emptyText}>{'아직 활동한\n 기록이 없어요.'}</Text>
                            <Text style={styles.emptyText}>{'처음 화면으로 돌아가\n봉사를 시작해보세요!'}</Text>
                        </View>
                    )
                }

            </View>
            <View style={styles.bottomWrapper}>
                <MainButton text={'내 정보 보기'} action={()=>{navigation.push('myInfo')}} type={ButtonType.default}/>
                <MainButton text={'처음 화면으로'} action={()=>{navigation.push('main')}} type={ButtonType.other}/>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        backgroundColor: '#FEFAED',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40),
        gap: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    titleWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleText: {
        color: '#469046',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    contentWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(480)
    },
    tableRow: {
        height: PixelRatio.getPixelSizeForLayoutSize(40),
        flexDirection: 'row',
        justifyContent: 'center',
    },
    tableItem: {
        borderWidth: 0.7,
        borderColor: '#B2B2B2',
        justifyContent: 'center'
    },
    tableTitle: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        textAlign: 'center',
    },
    tableText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        textAlign: 'center',
    },
    bottomWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    },
    emptyWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        gap: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    emptyText: {
        color: '#469046',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        textAlign: 'center',
    }
})

export default MyPlogging