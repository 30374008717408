import {StyleSheet, View, Image, PixelRatio, Pressable} from "react-native";
import logo from '../../assets/images/logo_.svg'
import login from '../../assets/images/login.svg'
import logout from '../../assets/images/logout.svg'
import user from '../../assets/images/user_.svg'
import {useEffect, useState} from "react";
import {addRefreshToken, client, removeToken} from "../../api/client.ts";
import {useMutation} from "@tanstack/react-query";
import {urls} from "../../api/Url.ts";
import AsyncStorage from "@react-native-async-storage/async-storage";

function TopMenu({navigation, route, userVisible=true}){

    const [isLogin, setIsLogin] = useState(false)

    useEffect(() => {
        const token = client.defaults.headers.common['Authorization']
        if(token===undefined) {
            setIsLogin(false)
        }else{
            setIsLogin(true)
        }
    }, [client.defaults.headers.common['Authorization']]);

    const logoutInstance = useMutation({
        mutationFn: ()=>client.post(urls['logout']),
        onSuccess: ({data})=>{
            if(data.status){
                removeToken()
                addRefreshToken(undefined)
                AsyncStorage.removeItem('token').then()
                AsyncStorage.removeItem('refreshToken').then()
                navigation.push('main')
            }
        }
    })

    return (
        <View style={styles.wrapper}>
            <Pressable
                onPress={()=>navigation.push('main')}
            >
                <Image source={logo} style={styles.logo} resizeMode={'stretch'} />
            </Pressable>

            {
                userVisible &&
                <View style={styles.horizontalWrapper}>
                    <Pressable
                        onPress={()=>{
                            if(isLogin)
                                navigation.push('myInfo',{})
                            else
                                navigation.push('login')
                        }}
                    >
                        <Image source={user} style={styles.icon} resizeMode={'stretch'} />
                    </Pressable>
                    <Pressable onPress={()=>{
                        if(isLogin) {
                            logoutInstance.mutate()
                        }else {
                            navigation.push('login')
                        }
                    }}>
                        <Image source={isLogin?logout:login} style={styles.icon} resizeMode={'stretch'} />
                    </Pressable>

                </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        position: 'static',
        top: PixelRatio.getPixelSizeForLayoutSize(15),
        left: 0,
        right: 0,
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(80),
        alignItems: 'center',
        justifyContent: 'center',
    },
    horizontalWrapper: {
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(30),
        gap: 20,
        flexDirection: 'row'
    },
    logo: {
        width: PixelRatio.getPixelSizeForLayoutSize(120),
        height: PixelRatio.getPixelSizeForLayoutSize(41.1),
    },
    icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(25),
        height: PixelRatio.getPixelSizeForLayoutSize(20),
    }
})

export default TopMenu