import {Image, ImageBackground, PixelRatio, StyleSheet, Text, View} from "react-native";
import Camera,{FACING_MODES} from 'react-html5-camera-photo'
import './camera.css'
import frame_1 from '../../assets/images/frame/frame_1.svg'
import frame_2 from '../../assets/images/frame/frame_2.svg'
import frame_3 from '../../assets/images/frame/frame_3.svg'
import frame_4 from '../../assets/images/frame/frame_4.svg'
import frame_5 from '../../assets/images/frame/frame_5.svg'
import {useState, useEffect, useRef} from "react";

function CameraPage({ploggingPont, toggleFacingMode= true, setErrorPopup, frameNumber= 0}){

    const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT)
    const [frameImage, setFrameImage] = useState(frame_1)

    useEffect(() => {
        switch (frameNumber) {
            case 0:
                setFrameImage(frame_1)
                break
            case 1:
                setFrameImage(frame_2)
                break
            case 2:
                setFrameImage(frame_3)
                break
            case 3:
                setFrameImage(frame_4)
                break
            case 4:
                setFrameImage(frame_5)
                break
        }
    }, [frameNumber]);

    useEffect(() => {
        if(toggleFacingMode)
            setFacingMode(FACING_MODES.ENVIRONMENT)
        else
            setFacingMode( FACING_MODES.USER)
    }, [toggleFacingMode]);

    return (
        <>
            <Image source={frameImage} style={styles.frameWrapper} resizeMode={'stretch'}/>
            <Camera
                onTakePhoto={(dataUri)=>{}}
                idealFacingMode = {facingMode}
                onCameraError={()=>setErrorPopup(true)}
                onCameraStop={()=>{}}
                onCameraStart={()=>{}}
            />
            <Text style={styles.rewardNumber}>{ploggingPont}개</Text>
        </>
    )
}

const styles = StyleSheet.create({
    frameWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
    },
    rewardNumber: {
        width: PixelRatio.getPixelSizeForLayoutSize(50),
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(35),
        bottom: PixelRatio.getPixelSizeForLayoutSize(24),
        color: '#DD7451',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '800',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
        textAlign: 'center',
        zIndex: 2
    },
})

export default CameraPage



