import {Image, Modal, PixelRatio, Pressable, StyleSheet, Text, View, ScrollView} from "react-native";
import logo from '../../assets/images/logo_help.svg'
import x from '../../assets/images/x_.svg'
import MainButton, {ButtonType} from "./MainButton.tsx";

function HelpPopup({visible, setVisible, title='[플로깅 즐기는 법]', subject='내용입니다.', action=()=>{}, useAction=true}) {
    return (
        <Modal
            visible={visible}
            transparent={true}
        >
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <View style={styles.topWrapper}>
                        <Image source={logo} style={styles.logo}/>
                        <Pressable
                            style={styles.xWrapper}
                            onPress={()=>setVisible(false)}
                        >
                            <Image source={x} style={styles.x}/>
                        </Pressable>

                    </View>
                    <Text style={styles.title}>{title}</Text>
                    <ScrollView  style={styles.contentTextWrapper}>
                        <View style={{alignItems: 'center'}}>
                            {subject}
                        </View>
                    </ScrollView>
                    <View style={styles.bottomWrapper}>
                        {useAction&&<MainButton text={'플로깅 시작하기'} action={action} type={ButtonType.default}/>}
                        <MainButton text={'창 닫기'} action={()=>{setVisible(false)}} type={ButtonType.other}/>
                    </View>
                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0009',
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(30),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    contentWrapper: {
        width: '100%',
        height: '100%',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25);',
        padding: PixelRatio.getPixelSizeForLayoutSize(20),
        gap: PixelRatio.getPixelSizeForLayoutSize(28),
        backgroundColor: '#fff'
    },
    topWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(15)
    },
    logo: {
        width: PixelRatio.getPixelSizeForLayoutSize(122),
        height: PixelRatio.getPixelSizeForLayoutSize(23)
    },
    xWrapper: {
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(35),
    },
    x: {

        width: PixelRatio.getPixelSizeForLayoutSize(20),
        height: PixelRatio.getPixelSizeForLayoutSize(20)
    },

    title: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(18),
        fontWeight: '800',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
    },
    subject: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '400',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
    },
    contentTextWrapper: {
        flex: 1
    },
    bottomWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    }
})

export default HelpPopup