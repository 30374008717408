export function getTintArrayNode(arr) {

    let end = 0
    let node = Array()
    let isBackward = false
    arr.forEach((v,i)=>{
        if(v>end){
            end = v - 1
            node[v-1] = '#469046'
        }else {
            node[v-1] = '#DD7451'
            isBackward = true
        }
    })

    if (isBackward)
        node[end] = '#DD7451'


    return node
}

export function getTintArrayRoad(arr){
    let end = 0
    let maxValue = 0
    let isBackward = false
    arr.forEach((v,i)=>{
        if(v>end){
            end = v - 1
            maxValue = i+1
        }else {
            isBackward = true
        }
    })

    const start = arr[0]
    let road = Array()
    for (let i = start; i<=end; i++) {
        road[i-1] = '#469046'
    }
    if (isBackward){
        for (let i = end; i>=arr[arr.length-1]; i--) {
            road[i-1] = '#DD7451'
        }
    }

    return road
}