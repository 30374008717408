export const urls = {
    signup: '/api/v1/user/signup',
    login: '/api/v1/user/login',
    refresh: '/api/v1/user/refresh',
    logout: '/api/v1/user/logout',
    update: '/api/v1/user/update',
    updateAddress: '/api/v1/user/updateaddress',
    whoami: '/api/v1/user/whoami',
    isValid: (uid,lon,lat)=>`/api/v1/position/isvalid?point=${uid}&longitude=${lon}&latitude=${lat}`,
    plogging_save: '/api/v1/plogging/save',
    plogging_find: '/api/v1/plogging/findby',
    plogging_update: '/api/v1/plogging/update',
    plogging_add: '/api/v1/plogging/addpoint',
    plogging_list: '/api/v1/plogging/getlist',
    file_upload: '/api/v1/file/fileupload',
    isEvent: '/api/v1/plogging/isevent'
}