import {PixelRatio, StyleSheet, Text, View} from "react-native";

import {useEffect, useState} from "react";
import {Scanner, useDevices} from '@yudiel/react-qr-scanner'
import TopMenu from "../components/public/TopMenu.tsx";
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";
import {timeoutText} from "../components/public/plogging/TimeoutText.tsx";
import {useMutation} from "@tanstack/react-query";
import {client} from "../api/client.ts";
import {urls} from "../api/Url.ts";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {alertText} from "../components/public/plogging/AlertText.tsx";


function QRPage({navigation, route}){
    const [closedPlogging, setClosedPlogging] = useState(false)
    const [isLocationError, setIsLocationError] = useState(false)
    const [isDirectionError, setIsDirectionError] = useState(false)
    const [paused, setPaused] = useState(false)

    const [isFirst, setIsFirst] = useState(false)

    const [isCameraError, setIsCameraError] = useState(false)
    const [unusedCamera, setUnusedCamera] = useState(false)

    const saveInstance = useMutation({
        mutationFn: (req: requests['plogging_save'])=> client.post<responseWrapper<responses['plogging_save']>>(urls['plogging_save'],req),
        onSuccess: ({data})=> {
            if(data.status) {
                navigation.push('plogging')
            }else {
                if(data.errorcode===901){
                    return setClosedPlogging(true)
                } else {
                    setIsDirectionError(true)
                    navigation.push('plogging')
                }

            }
        },
        onError: (error)=> setPaused(false)
    })

    const addInstance = useMutation({
        mutationFn: (req: requests['plogging_add'])=>
            client.post<responseWrapper<responses['plogging_save']>>(urls['plogging_add'],req),
        onSuccess: ({data})=> {
            if(data.status) {
                navigation.push('plogging')
            }else {
                if(data.errorcode===901){
                    return setClosedPlogging(true)
                } else {
                    setIsDirectionError(true)
                    navigation.push('plogging')
                }
            }
        },
        onError: ()=> setPaused(false)
    })



    useEffect( () => {
        setIsFirst(route.params?.isFirst)

        setUnusedCamera(route.params?.unusedCamera)

    }, []);


    return (
        <View style={styles.wrapper}>
            <PopupModal visible={isLocationError} setVisible={setIsLocationError} type={PopupType.alert} text={alertText}/>
            <PopupModal visible={isDirectionError} setVisible={setIsDirectionError} type={PopupType.alert}
                        text={'마지막 장소와 같거나 방향이 잘못되었습니다.'}/>
            <PopupModal visible={closedPlogging} setVisible={setClosedPlogging}
                        type={PopupType.alert} text={timeoutText} action={()=>{
                            setClosedPlogging(false)
                            navigation.push('main')
            }}/>
            <PopupModal
                visible={unusedCamera}
                setVisible={setUnusedCamera}
                text={'GPS 및 카메라 이용에\n동의해 주세요. 미 동의시\n서비스 이용이 불가능합니다.'}
                type={PopupType.default}
                iconType={IconType.gps}
                action={()=>{
                    navigation.push('main')
                    setUnusedCamera(false)
                }}
            />
            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.photoWrapper}>
                <Scanner
                    onScan={(result)=>{
                        const value = result[0].rawValue
                        const url = new URL(value)

                        const hostName = `${url.hostname}${url.pathname}`
                        const point = parseInt(url.searchParams.get('number'))



                        if (hostName==='hsvolunteer.eco-plogging.com/qr') {
                            setPaused(true)
                            navigator.geolocation.getCurrentPosition((position)=>{
                                const lon = position.coords.longitude;
                                const lat = position.coords.latitude;
                                client.get(urls['isValid'](point, lon, lat)).then(({data})=>{
                                    if(data.status) {
                                        if (data.data) {
                                            if (isFirst) {
                                                const req: requests['plogging_save'] = {
                                                    pointList: [point],
                                                    isReverse: false,
                                                    isEnd: false
                                                }
                                                saveInstance.mutate(req)
                                            }else {
                                                const req:requests['plogging_add'] = {
                                                    point: point
                                                }
                                                addInstance.mutate(req)
                                            }
                                        }else {
                                            setIsLocationError(true)
                                            navigation.push('plogging')
                                        }
                                    }else
                                        alert(data.errorcode)
                                })
                            })
                        }

                    }}
                    components={{
                        audio: false,
                        finder: false,
                        torch: false,
                        zoom: false,
                        onOff: false
                    }}
                    paused={paused}

                />
            </View>
        </View>

    )
}

const styles = StyleSheet.create({
    wrapper: {
        width:'100vw',
        height:'100vh',
        backgroundColor: '#FEFAED',
        alignItems: 'center'
    },
    photoWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(300),
        height: PixelRatio.getPixelSizeForLayoutSize(428),
        alignItems: 'center',
        justifyContent: 'center',
    },
})

export default QRPage