import {Image, PixelRatio, StyleSheet, Text, View} from "react-native";
import {useEffect, useState} from "react";
import arrow_ from '../assets/images/arrow_right.svg'
import TopMenu from "../components/public/TopMenu.tsx";
import InputLine from "../components/acount/InputLine.tsx";
import CheckBox from "../components/acount/CheckBox.tsx";
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import BottomView from "../components/public/BottomView.tsx";
import {client} from "../api/client.ts";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {urls} from "../api/Url.ts";
import PopupModal, {IconType, PopupType} from "../components/public/PopupModal.tsx";

function Account({navigation, route}){

    const [popupVisible, setPopupVisible] = useState(false)
    const [idCheck, setIdCheck] = useState(false)
    const [telCheck, setTelCheck] = useState(false)

    const [id, setId] = useState('')
    const [password, setPassword] = useState('')
    const [passwordCheck, setPasswordCheck] = useState('')

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [birth, setBirth] = useState('')
    const [volunteer1365, setVolunteer1365] = useState('')

    const [allCheck, setAllCheck] = useState(false)
    const [terms1, setTerms1] = useState(false)
    const [terms2, setTerms2] = useState(false)

    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        if(password === passwordCheck) {
            setIsChecked(true)
        }else {
            setIsChecked(false)
        }
    }, [password,passwordCheck]);

    useEffect(() => {
        if(allCheck) {
            setTerms1(true)
            setTerms2(true)
        }else{
            setTerms1(false)
            setTerms2(false)
        }
    }, [allCheck]);

    useEffect(() => {
        if(terms1&&terms2){
            setAllCheck(true)
        }
    }, [terms1,terms2]);

    const signupInstance = useMutation({
        mutationFn: (req: requests['signup'])=> client.post<responseWrapper<responses['signup']>>(urls['signup'],req),
        onSuccess: ({data})=>{
            if(data.status) {
                navigation.pop()
            }else{
                if(data.errorcode===100)
                    setIdCheck(true)
                else if(data.errorcode===103)
                    setTelCheck(true)
            }
        }
    })


    return (
        <View style={styles.wrapper}>

            <PopupModal visible={popupVisible} setVisible={setPopupVisible} type={PopupType.default}
                        iconType={IconType.check} text={
                    '봉사 내역 기록을 위한\n이용약관에 동의해주세요.\n미 동의시 회원 등록이 불가합니다.'
            }/>
            <PopupModal visible={idCheck} setVisible={setIdCheck} type={PopupType.default}
                        iconType={IconType.check} text={
                '이미 사용 중인\n아이디입니다.'
            }/>
            <PopupModal visible={telCheck} setVisible={setTelCheck} type={PopupType.default}
                        iconType={IconType.check} text={'이미 사용 중인\n전화번호입니다.'}/>

            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.contentWrapper}>
                <View style={styles.titleWrapper}>
                    <Text style={styles.titleText}>{'봉사점수 입력을 위한\n가입 정보를 입력해주세요.'}</Text>
                </View>
                <View style={styles.separator_top}/>
                <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(12)}}>
                    <InputLine subject={'아이디'} text={id} setText={setId} addStar={true} hint={'아이디'}/>
                    <View>
                        <InputLine subject={'비밀번호'} text={password} setText={setPassword} addStar={false} hint={'비밀번호'} isSecurity={true}/>
                        <InputLine subject={''} text={passwordCheck} setText={setPasswordCheck} addStar={true} hint={'비밀번호 확인'} isSecurity={true} useSubject={false}/>
                    </View>
                </View>


                <View style={styles.checkPasswordWrapper}>
                    {password!=='' && <Text style={[styles.checkPasswordText, !isChecked&&styles.failedCheck]}>비밀번호가 {isChecked?'일치합니다':'일치하지않습니다'}.</Text>}
                </View>

                <View style={styles.otherWrapper}>
                    <InputLine subject={'이름'} text={name} setText={setName} addStar={true} hint={'이름을 입력하세요.'} />
                    <InputLine subject={'연락처'} text={phone} setText={setPhone} addStar={true} isPhone={true}/>
                    <InputLine subject={'생년월일'} text={birth} setText={setBirth} addStar={true} hint={'8자리 숫자로 입력하세요. (YYYYMMDD)'} isBirth={true}/>
                </View>
                <View style={styles.allCheckBoxWrapper}>
                    <CheckBox check={allCheck} setCheck={setAllCheck} size={25}/>
                    <Text style={styles.allCheckText}>약관 전체 동의</Text>
                </View>
                <View style={styles.separator_bottom}/>
                <View style={styles.bottomWrapper}>
                    <View style={styles.checkBoxWrapper}>
                        <CheckBox check={terms1} setCheck={setTerms1} size={20}/>
                        <Text style={styles.allCheckText}>서비스 이용 약관에 동의합니다.</Text>
                        <Image source={arrow_} style={styles.arrow}/>
                    </View>
                    <View style={styles.checkBoxWrapper}>
                        <CheckBox check={terms2} setCheck={setTerms2} size={20}/>
                        <Text style={styles.allCheckText}>개인정보 수집・이용에 동의합니다.</Text>
                        <Image source={arrow_} style={styles.arrow}/>
                    </View>
                </View>
                <MainButton text={'제출하기'} type={ButtonType.default} action={()=>{
                    if (!allCheck) {
                        setPopupVisible(true)
                        return;
                    }
                    if(id.length<1 || !isChecked || name.length<1 || birth.length<1 || phone<11) {
                        alert('필수 입력이 누락 되었습니다')
                        return
                    }
                    const req: requests['signup'] = {
                        id: id,
                        password: password,
                        name: name,
                        address: undefined,
                        birthday: birth,
                        role: 'USER',
                        tel: phone,
                        volunteer1365: volunteer1365
                    }
                    signupInstance.mutate(req)
                }}/>
            </View>
            <BottomView/>


        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        backgroundColor: '#FEFAED',

    },
    contentWrapper: {
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40),
    },
    titleWrapper: {
        width: '70vw',
        alignItems: 'center'
    },
    titleText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        color: '#469046',
        textAlign: 'center'
    },
    separator_top: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#B2B2B2',
        marginTop: PixelRatio.getPixelSizeForLayoutSize(40),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(45)
    },
    checkPasswordWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(3),
        height: PixelRatio.getPixelSizeForLayoutSize(23)
    },
    checkPasswordText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        color: '#3C7FB3',
        lineHeight: '160%',
    },
    failedCheck: {
        color: 'red',
    },
    otherWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(15)
    },
    allCheckBoxWrapper: {
        marginTop: PixelRatio.getPixelSizeForLayoutSize(35),
        flexDirection: 'row',
        gap: PixelRatio.getPixelSizeForLayoutSize(10),
        alignItems: 'center'
    },
    allCheckText: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        color: '#666565',
        flex: 1,
    },
    bottomWrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(27),
    },
    checkBoxWrapper: {
        flexDirection: 'row',
        gap: PixelRatio.getPixelSizeForLayoutSize(10),
        alignItems: 'center'
    },
    separator_bottom: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(1),
        backgroundColor: '#B2B2B2',
        marginVertical: PixelRatio.getPixelSizeForLayoutSize(13),
    },
    arrow: {
        width: PixelRatio.getPixelSizeForLayoutSize(11),
        height: PixelRatio.getPixelSizeForLayoutSize(7),
    }
})

export default Account