
import check1 from '../../assets/images/check_1.svg'
import check2 from '../../assets/images/check_2.svg'
import {Image, PixelRatio, Pressable} from "react-native";

function CheckBox({setCheck, check, size = 10}){

    const pixelSize = PixelRatio.getPixelSizeForLayoutSize(size)

    return (
        <Pressable onPress={()=>setCheck(!check)}>
            <Image source={check?check2:check1} style={{width: pixelSize, height: pixelSize}}/>
        </Pressable>
    )
}

export default CheckBox