import {Image, PixelRatio, Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import {useState} from "react";
import TopMenu from "../components/public/TopMenu.tsx";
import CheckBox from "../components/acount/CheckBox.tsx";
import arrow_ from '../assets/images/arrow_right.svg'
import location_ from '../assets/images/location_.svg'
import MainButton, {ButtonType} from "../components/public/MainButton.tsx";
import DaumPostcodeEmbed, {useDaumPostcodePopup} from "react-daum-postcode";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {client} from "../api/client.ts";
import {urls} from "../api/Url.ts";
import PopupModal, {PopupType} from "../components/public/PopupModal.tsx";


function EventPage({navigation, route}){

    const [isCheck, setIsCheck] = useState(false)
    const [address, setAddress] = useState('')
    const [addressDetail, setAddressDetail] = useState('')
    const [isCheckVisible, setIsCheckVisible] = useState(false)
    const [isEmptyAddress, setIsEmptyAddress] = useState(false)

    const open_daumPost = useDaumPostcodePopup()

    const updateInstance = useMutation({
        mutationFn: (req: requests['updateAddress'])=> client.post<responseWrapper<responses['signup']>>(urls['updateAddress'],req),
        onSuccess: ({data})=>{
            if(data.status) {
                navigation.push('main')
            }else {
                alert(data.errorcode)
            }
        },
        onError: (error)=> alert(error)
    })
    
    return (
        <View style={styles.wrapper}>

            <PopupModal
                visible={isCheckVisible}
                setVisible={setIsCheckVisible}
                text={'개인정보 활용에\n동의가 필요합니다.'}
                type={PopupType.default}
                buttonText={'확인'}
            />

            <PopupModal
                visible={isEmptyAddress}
                setVisible={setIsEmptyAddress}
                text={'선물 수령지를\n등록해주세요.'}
                type={PopupType.default}
                buttonText={'확인'}
            />

            <TopMenu navigation={navigation} route={route} userVisible={false}/>
            <View style={styles.informationWrapper}>
                <Text style={styles.informationTitle}>우수 봉사자 EVENT!</Text>
                <Text style={styles.informationSubject}>
                    화성시 자원봉사센터가{'\n'}
                    열정적인 활동가 분들께 드리는 깜짝 선물!{'\n'}
                    폐플라스틱으로 만든 귀여운 키링과{'\n'}
                    실물 완주증을 집으로 보내드려요 (1인 1회){'\n'}
                </Text>
                <View style={styles.informationCheckWrapper}>
                    <CheckBox check={isCheck} setCheck={setIsCheck} size={20} />
                    <Text style={styles.informationCheckText}>개인정보 활용 동의</Text>
                    <Pressable>
                        <Image source={arrow_} style={styles.arrow_}/>
                    </Pressable>
                </View>
            </View>
            <View style={styles.contentWrapper}>
                <Text style={styles.contentTitle}>선물 수령지<Text style={{color: '#DD7451'}}>*</Text></Text>
                <View style={{gap: PixelRatio.getPixelSizeForLayoutSize(9)}}>
                    <Pressable style={styles.horizontalWrapper} onPress={()=>{
                        open_daumPost({
                            onComplete:(data)=> {
                                if(data.userSelectedType==='R')
                                    setAddress(data.roadAddress)
                                else
                                    setAddress(data.jibunAddress)
                            }
                        })
                    }}>
                        <TextInput style={styles.unEditableInput} editable={false}
                                   placeholder={'건물, 지번 또는 도로명 검색'} placeholderTextColor={'#666565'} value={address}/>
                        <Image source={location_} style={styles.location_}/>
                    </Pressable>
                    <TextInput style={styles.textInput} placeholder={'상세 주소'}
                               value={addressDetail} onChangeText={(v)=>setAddressDetail(v)}/>
                </View>
                <View style={styles.cautionWrapper}>
                    <Text style={styles.cautionText}>
                        *수령자 및 연락처는 회원가입 시 입력한{'\n'}
                        봉사자 정보 그대로 입력됩니다.
                    </Text>
                    <Text style={styles.cautionText}>
                        *신청서 제출 후 배송 주소 변경이 불가하니{'\n'}
                        정확하게 작성 바랍니다.
                    </Text>
                </View>

                <MainButton text={'완주증 & 기념품 신청서 제출'} action={()=>{
                    if (!isCheck)
                        return setIsCheckVisible(true)
                    const req: requests['updateAddress'] = {
                        address: `${address} ${addressDetail}`
                    }
                    if(req.address.trim().length === 0)
                        return setIsEmptyAddress(true)
                    updateInstance.mutate(req)
                }} type={ButtonType.default}/>
                <MainButton text={'다음에 받기'} type={ButtonType.cancel}
                            action={()=>{
                                navigation.push('main')
                            }} />

            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        backgroundColor: '#FEFAED',
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(40)
    },
    informationWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(289),
        backgroundColor: '#3F3B3A',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(43),
    },
    informationTitle: {
        color: '#FEFAED',
        textAlign: 'center',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '800',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    informationSubject: {
        color: '#FEFAED',
        textAlign: 'center',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '400',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
        marginTop: PixelRatio.getPixelSizeForLayoutSize(21),
        marginBottom: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    informationCheckWrapper: {
        width: '100%',
        flexDirection: 'row',
        height: PixelRatio.getPixelSizeForLayoutSize(42),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(21),
        backgroundColor: '#FEFAED',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(14),
        alignItems: 'center',
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    },
    informationCheckText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        flex: 1
    },
    arrow_: {
        width: PixelRatio.getPixelSizeForLayoutSize(11),
        height: PixelRatio.getPixelSizeForLayoutSize(6.68)
    },
    contentWrapper: {
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(38),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(45),
        gap: PixelRatio.getPixelSizeForLayoutSize(9),
    },
    contentTitle: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700',
    },
    horizontalWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: PixelRatio.getPixelSizeForLayoutSize(12),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#666565',
    },
    unEditableInput: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        flex: 1,
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(12),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(11),
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
    },
    location_: {
        width: PixelRatio.getPixelSizeForLayoutSize(20),
        height: PixelRatio.getPixelSizeForLayoutSize(25)
    },
    textInput: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        flex: 1,
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderColor: '#666565',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(12),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(11),
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
    },
    cautionWrapper: {
        alignItems: 'center',
        paddingTop: PixelRatio.getPixelSizeForLayoutSize(29),
        gap: PixelRatio.getPixelSizeForLayoutSize(9),
    },
    cautionText: {
        color: '#DD7451',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        lineHeight: '160%',
        textAlign: 'center'
    }
})

export default EventPage