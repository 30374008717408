import {StyleSheet, Modal, View, PixelRatio, Text, Image} from "react-native";
import alert_icon from '../../assets/images/alert_icon.svg'
import gps_icon from '../../assets/images/gps_.svg'
import check_icon from '../../assets/images/check_icon.svg'
import MainButton, {ButtonType} from "./MainButton.tsx";
import {useEffect, useState} from "react";

function PopupModal({visible, setVisible, type = PopupType.default, text='',
                        action=()=>{setVisible(false)}, buttonText = '확인', iconType = IconType.none}){
    const [iconSize, setIconSize] = useState({})
    const [icon, setIcon] = useState()
    useEffect(() => {
        switch (iconType) {
            case IconType.check:
                setIcon(check_icon)
                setIconSize({
                    width: PixelRatio.getPixelSizeForLayoutSize(54),
                    height: PixelRatio.getPixelSizeForLayoutSize(54)
                })
                break
            case IconType.gps:
                setIcon(gps_icon)
                setIconSize({
                    width: PixelRatio.getPixelSizeForLayoutSize(40.8),
                    height: PixelRatio.getPixelSizeForLayoutSize(51)
                })
                break
        }
    }, []);

    return (
        <Modal
            visible={visible}
            transparent={true}
        >
            <View style={styles.wrapper}>
                <View style={styles.contentWrapper}>
                    <View style={styles.textWrapper}>
                        {iconType!==IconType.none&&<Image source={icon} style={iconSize}/>}
                        {
                            (type===PopupType.alert||type===PopupType.alertConfirm)&&
                            <Image source={alert_icon} style={styles.alert_icon}/>
                        }
                        <Text style={
                            type===PopupType.alert||type===PopupType.alertConfirm?styles.alertText:styles.defaultText
                        }>
                            {text}
                        </Text>
                    </View>
                    <View style={
                        (type===PopupType.confirm||type===PopupType.alertConfirm)&&styles.horizontalWrapper
                    }>
                        <MainButton text={buttonText} type={
                            type===PopupType.alert||type===PopupType.alertConfirm?ButtonType.alert:ButtonType.default
                        } action={action}/>
                        {
                            (type===PopupType.confirm||type===PopupType.alertConfirm)&&
                            <MainButton text={'취소'} type={ButtonType.cancel} action={()=>setVisible(false)}/>
                        }
                    </View>


                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0009',
        padding: PixelRatio.getPixelSizeForLayoutSize(20)
    },
    contentWrapper: {
        width: '100%',
        minHeight: PixelRatio.getPixelSizeForLayoutSize(273),
        backgroundColor: '#fff',
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(10),
        padding: PixelRatio.getPixelSizeForLayoutSize(20),
        gap: PixelRatio.getPixelSizeForLayoutSize(33),
        boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25);'
    },
    textWrapper: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 5,
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(15),
        gap: PixelRatio.getPixelSizeForLayoutSize(3)
    },
    defaultText: {
        color: '#469046',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        textAlign: 'center',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    alertText: {
        color: '#DD7451',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        textAlign: 'center',
    },
    alert_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(55),
        height: PixelRatio.getPixelSizeForLayoutSize(50),
    },
    horizontalWrapper: {
        flexDirection: 'row',
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
    }
})

export enum  PopupType {
    default,
    alert,
    confirm,
    alertConfirm
}

export enum IconType {
    none,
    gps,
    check,
}

export default PopupModal