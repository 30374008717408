import {PixelRatio, StyleSheet, Text, View} from "react-native";

function HelpView({marginTop = 0}){
    return (
        <View style={[styles.helpWrapper,{marginTop: PixelRatio.getPixelSizeForLayoutSize(marginTop)}]}>
            <Text style={styles.helpTitle}>비밀번호 분실 시 {'\n'}자원봉사센터로 연락바랍니다.</Text>
            <Text style={styles.helpSubject}>☎ 031-8059-5690</Text>
        </View>
    )
}

const styles = StyleSheet.create({
    helpWrapper: {
        flex: 1,
        alignItems: 'center',
    },
    helpTitle: {
        color: '#DD7451',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '500',
        lineHeight: '120%',
        textAlign: 'center',
    },
    helpSubject: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '400',
        lineHeight: '160%',
        textAlign: 'center',
    }
})

export default HelpView