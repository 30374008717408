import {PixelRatio, StyleSheet, Text, View} from "react-native";

function BottomView(){
    return (
        <View style={styles.wrapper}>
            <View>
                <Text style={styles.title}>(사)화성시자원봉사센터</Text>
                <Text style={styles.subject}>
                    (18603) 경기도 화성시 향남읍 행정중앙1로 30{'\n'}
                    전화번호 | 031-8059-5680 ~ 2{'\n'}
                    이메일 | hscity1365@hanmail.net
                </Text>
            </View>
            <View>
                <Text style={styles.title}>스테디윈스(주)</Text>
                <Text style={styles.subject}>
                    Copyright 2024. steadywins Inc. All Rights Reserved.
                </Text>
            </View>

        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100%',
        height: PixelRatio.getPixelSizeForLayoutSize(160),
        backgroundColor: '#666565',
        gap: PixelRatio.getPixelSizeForLayoutSize(12),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(26),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(20),
    },
    title: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '700',
        lineHeight: '130%'
    },
    subject: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        lineHeight: '130%'
    }
})

export default BottomView
