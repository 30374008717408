import {StyleSheet, Text, View, TextInput, PixelRatio} from "react-native";
import {useEffect, useState} from "react";

function InputLine({subject, text, setText, hint='', addStar = false,
                       isSecurity = false, isBirth = false, useSubject = true,
                       isPhone = false, useColor = false, isUse = true
}){
    const [front, setFront] = useState('')
    const [middle, setMiddle] = useState('')
    const [back, setBack] = useState('')

    useEffect(() => {
        const full = `${front}${middle}${back}`

        if (text !== full && full.length >= 11) {
            setText(full)
        }
    }, [front,middle,back]);

    useEffect(() => {
        if(isPhone && text.length<=11) {
            const t = `${text}`
            setFront(t.substring(0,3))
            setMiddle(t.substring(3,7))
            setBack(t.substring(7, 11))
        }
    }, [text]);



    if (isPhone)

        return (
            <View style={styles.wrapper}>
                {useSubject && <Text style={styles.subject}>{subject}{addStar&&<Text style={styles.star}> *</Text>}</Text>}
                <View style={{flexDirection: 'row', alignItems: 'center', gap: PixelRatio.getPixelSizeForLayoutSize(9)}}>
                    <TextInput
                        style={[styles.inputText, {width: '30%'}]}
                        value={front}
                        onChangeText={(v)=> {
                            if (v.length<=3)
                                setFront(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                    <Text>-</Text>
                    <TextInput
                        style={[styles.inputText, {width: '40%'}]}
                        value={middle}
                        onChangeText={(v)=>{
                            if (v.length<=4)
                                setMiddle(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                    <Text>-</Text>
                    <TextInput
                        style={[styles.inputText, {width: '40%'}]}
                        value={back}
                        onChangeText={(v)=>{
                            if (v.length<=4)
                                setBack(v)
                        }}
                        placeholderTextColor={'#666565'}
                        keyboardType={"number-pad"}
                    />
                </View>
            </View>
        )
    return (
        <View style={styles.wrapper}>
            {useSubject && <Text style={[styles.subject, useColor && styles.star]}>
                {subject}{addStar&&<Text style={styles.star}> *</Text>}
            </Text>}
            <TextInput
                style={[styles.inputText, useColor && styles.star, !isUse&&{backgroundColor:'#EDEDED'}]}
                value={text}
                onChangeText={(v)=>{
                    if (isBirth) {
                        if(v.length<=8) {
                            setText(v)
                        }
                    }else
                        setText(v)
                }}
                placeholder={hint}
                placeholderTextColor={useColor?'#DD7451':'#666565'}
                editable={isUse}
                secureTextEntry={isSecurity}
                keyboardType={isBirth&&"number-pad"}
            />
        </View>
    )
}

const styles = StyleSheet.create({
    wrapper: {
        gap: PixelRatio.getPixelSizeForLayoutSize(3),
    },
    subject: {
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '800',
        color: '#666565',
    },
    star: {
        color: '#DD7451',
    },
    inputText: {
        height: PixelRatio.getPixelSizeForLayoutSize(44),
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(0.7),
        borderColor: '#B2B2B2',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(12),
        paddingVertical: PixelRatio.getPixelSizeForLayoutSize(11),
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        color: '#666565',
    }
})

export default InputLine