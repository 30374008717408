import React, {useEffect, useState} from "react";
import {Platform, Text, View} from "react-native-web";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {NavigationContainer, useNavigation} from "@react-navigation/native";
import Main from "./screens/Main.tsx";
import Account from "./screens/Account.tsx";
import Login from "./screens/Login.tsx";
import Plogging from "./screens/Plogging.tsx";
import MyPlogging from "./screens/MyPlogging.tsx";
import EditMyInfo from "./screens/EditMyInfo.tsx";
import Reward from "./screens/Reward.tsx";
import QRPage from "./screens/QRPage.tsx";
import MyInfo from "./screens/MyInfo.tsx";
import EventPage from "./screens/EventPage.tsx";
import {QueryClient, QueryClientProvider,} from "@tanstack/react-query";

function App(){

    const Stack = createNativeStackNavigator()
    const queryClient = new QueryClient();

    const [unusedCamera, setUnusedCamera] = useState(false)


    return (
        <QueryClientProvider client={queryClient}>
            <NavigationContainer onReady={()=>{
                navigator.mediaDevices?.getUserMedia({video: true}).catch(()=>{
                    setUnusedCamera(true)
                })
            }}>

                <Stack.Navigator screenOptions={{title: '화성시'}}>
                    <Stack.Screen name={'main'} component={Main}/>
                    <Stack.Screen name={'account'} component={Account}/>
                    <Stack.Screen name={'login'} component={Login}/>
                    <Stack.Screen name={'plogging'} component={Plogging}/>
                    <Stack.Screen name={'myPlogging'} component={MyPlogging}/>
                    <Stack.Screen name={'editMyInfo'} component={EditMyInfo}/>
                    <Stack.Screen name={'myInfo'} component={MyInfo}/>
                    <Stack.Screen name={'reward'} component={Reward}/>
                    <Stack.Screen name={'qrPage'} component={QRPage} initialParams={{unusedCamera: unusedCamera}}/>
                    <Stack.Screen name={'eventPage'} component={EventPage}/>
                </Stack.Navigator>
            </NavigationContainer>
        </QueryClientProvider>

    )
}

export default App;