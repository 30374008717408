import {Image, PixelRatio, ScrollView, StyleSheet, Text, View} from "react-native";
import help_text from '../../../assets/images/help_text.svg'

export const helpText =
    <Image source={help_text} style={{
        width: PixelRatio.getPixelSizeForLayoutSize(300),
        height: PixelRatio.getPixelSizeForLayoutSize(521),
    }} resizeMode={'stretch'}/>


