import {Image, ImageBackground, PixelRatio, Pressable, StyleSheet, Text, View} from "react-native";
import road from '../assets/images/road.svg'
import logo from '../assets/images/logo_plogging.svg'
import qr_icon from '../assets/images/qr_icon.svg'
import bg_ from '../assets/images/bg_plogging.svg'
import stamp_l from '../assets/images/stamp_l.svg'
import stamp_s from '../assets/images/stamp_s.svg'
import road_1 from '../assets/images/road/road_1.svg'
import road_2 from '../assets/images/road/road_2.svg'
import road_3 from '../assets/images/road/road_3.svg'
import road_4 from '../assets/images/road/road_4.svg'
import road_5 from '../assets/images/road/road_5.svg'
import road_6 from '../assets/images/road/road_6.svg'
import road_7 from '../assets/images/road/road_7.svg'
import road_8 from '../assets/images/road/road_8.svg'
import road_9 from '../assets/images/road/road_9.svg'
import animal_1 from '../assets/images/animal_1.svg'
import animal_2 from '../assets/images/animal_2.svg'
import animal_3 from '../assets/images/animal_3.svg'
import animal_4 from '../assets/images/animal_4.svg'
import HelpPopup from "../components/public/HelpPopup.tsx";
import {useEffect, useState} from "react";
import {helpText} from "../components/public/plogging/HelpText.tsx";
import PopupModal, {PopupType} from "../components/public/PopupModal.tsx";
import {backText} from "../components/public/plogging/BackText.tsx";
import {alertText} from "../components/public/plogging/AlertText.tsx";
import {timeoutText} from "../components/public/plogging/TimeoutText.tsx";
import {useMutation} from "@tanstack/react-query";
import {requests, responses, responseWrapper} from "../api/Entity.ts";
import {client} from "../api/client.ts";
import {urls} from "../api/Url.ts";
import {getTintArray, getTintArrayNode, getTintArrayRoad} from "../components/public/plogging/TintArray.ts";
import * as moment from "moment";

function Plogging({navigation, route}){

    const [helpVisible, setHelpVisible] = useState(false)
    const [backVisible, setBackVisible] = useState(false)

    const [closedPlogging, setClosedPlogging] = useState(false)

    const [endVisible, setEndVisible] = useState(false)

    const [isBack, setIsBack] = useState(false)
    const [isEnd, setIsEnd] = useState(false)

    const [isFirst, setIsFirst] = useState(false)
    
    const [tintArrayNode, setTintArrayNode] = useState([])
    const [tintArrayRoad, setTintArrayRoad] = useState([])

    const findPloggingInstance = useMutation({
        mutationFn: ()=> client.post<responseWrapper<responses['plogging_find']>>(urls['plogging_find']),
        onSuccess: ({data})=> {
            if(data.status) {
                setIsBack(data.data.isReverse)
                setIsEnd(data.data.isEnd)
                if(data.data.isEnd)
                    navigation.push('reward')
                const array = data.data.pointList

                if (array.length < 1) {
                    setIsFirst(true)
                }


                setTintArrayRoad(getTintArrayRoad(array))
                setTintArrayNode(getTintArrayNode(array))
                //setTintArrayRoad(getTintArrayRoad([10,3,2,1]))
                //setTintArrayNode(getTintArrayNode([10,3,2,1]))
            }else {
                if (data.errorcode === 901) {
                    setClosedPlogging(true)
                }
            }
        }
    })

    const updateInstance = useMutation({
        mutationFn: (req: requests['plogging_update'])=>client.post<responseWrapper<responses['plogging_save']>>(urls['plogging_update'],req),
        onSuccess: ({data})=>{
            if(data.status) {
                setIsBack(data.data.isReverse)
                setIsEnd(data.data.isEnd)
                setBackVisible(false)
            }

        }
    })

    useEffect(() => {
        findPloggingInstance.mutate()
    }, []);

    return(
        <View style={styles.wrapper}>
            <HelpPopup visible={helpVisible} setVisible={setHelpVisible}  subject={helpText} useAction={false}/>
            <PopupModal visible={backVisible} setVisible={setBackVisible}
                        type={PopupType.confirm} text={backText} buttonText={'되돌아가기'}
                        action={()=>{
                            const req: requests['plogging_update'] = {
                                isReverse: true,
                                isEnd: isEnd
                            }
                            updateInstance.mutate(req)
                        }}
            />

            <PopupModal visible={closedPlogging} setVisible={setClosedPlogging}
                        type={PopupType.alert} text={timeoutText}
                        action={()=>{
                            navigation.push('main')
                            setClosedPlogging(false)
                        }}
            />
            <PopupModal visible={endVisible} setVisible={setEndVisible} type={PopupType.alertConfirm}
                        buttonText={'종료하기'} text={
                <Text>
                    플로깅 종료 <Text style={{color: '#666565'}}>시 되돌릴 수 없어요.{'\n'}(내일 재참여 가능)</Text>
                </Text>}
                        action={()=>{
                            const req: requests['plogging_update'] = {
                                isReverse: isBack,
                                isEnd: true,
                                endTime: Date.now()
                            }
                            updateInstance.mutate(req,{
                                onSuccess:({data})=> {
                                    if(data.status) {
                                        navigation.push('reward')
                                        setEndVisible(false)
                                    }

                                }
                            })
                        }}
            />
            <View style={styles.topWrapper}>
                <Pressable onPress={()=>navigation.push('main')}>
                    <Image source={logo} style={styles.logo} resizeMode={'stretch'}/>
                </Pressable>

                <Pressable style={styles.helpWrapper} onPress={()=>setHelpVisible(true)}>
                    <Text style={styles.helpText}>도움말</Text>
                </Pressable>
            </View>
            <View style={{alignItems: 'center'}}>
                <ImageBackground source={bg_} style={styles.contentWrapper} resizeMode={'stretch'}>
                    <View style={styles.roadWrapper}>
                        <Image source={stamp_l} style={[styles.stampLarge, styles.stamp_lAxis1]} tintColor={tintArrayNode[0]}/>
                        <Text style={getTextAxis(styles.stamp_lAxis1, true)}>
                            {'QR-01\n'}<Text style={styles.mapText}>화성호 초입</Text>
                        </Text>
                        <Image source={road_1} style={[styles.road_1]} tintColor={tintArrayRoad[0]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis1]} tintColor={tintArrayNode[1]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis1, false)}>{'QR-02'}</Text>
                        <Image source={road_2} style={[styles.road_2]} tintColor={tintArrayRoad[1]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis2]} tintColor={tintArrayNode[2]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis2, false)}>{'QR-03'}</Text>
                        <Image source={road_3} style={[styles.road_3]} tintColor={tintArrayRoad[2]}/>
                        <Image source={stamp_l} style={[styles.stampLarge, styles.stamp_lAxis2]} tintColor={tintArrayNode[3]}/>
                        <Text style={getTextAxis(styles.stamp_lAxis2, true)}>
                            {'QR-04\n'}<Text style={styles.mapText}>매항항</Text>
                        </Text>
                        <Image source={road_4} style={[styles.road_4]} tintColor={tintArrayRoad[3]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis3]} tintColor={tintArrayNode[4]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis3, false)}>{'QR-05'}</Text>
                        <Image source={road_5} style={[styles.road_5]} tintColor={tintArrayRoad[4]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis4]} tintColor={tintArrayNode[5]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis4, false)}>{'QR-06'}</Text>
                        <Image source={road_6} style={[styles.road_6]} tintColor={tintArrayRoad[5]}/>
                        <Image source={stamp_l} style={[styles.stampLarge, styles.stamp_lAxis3]} tintColor={tintArrayNode[6]}/>
                        <Text style={getTextAxis(styles.stamp_lAxis3, true)}>{'QR-07'}</Text>
                        <Image source={road_7} style={[styles.road_7]} tintColor={tintArrayRoad[6]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis5]} tintColor={tintArrayNode[7]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis5, false)}>{'QR-08'}</Text>
                        <Image source={road_8} style={[styles.road_8]} tintColor={tintArrayRoad[7]}/>
                        <Image source={stamp_s} style={[styles.stampSmall, styles.stamp_sAxis6]} tintColor={tintArrayNode[8]}/>
                        <Text style={getTextAxis(styles.stamp_sAxis6, false)}>{'QR-09'}</Text>
                        <Image source={road_9} style={[styles.road_9]} tintColor={tintArrayRoad[8]}/>
                        <Image source={stamp_l} style={[styles.stampLarge, styles.stamp_lAxis4]} tintColor={tintArrayNode[9]}/>
                        <Text style={getTextAxis(styles.stamp_lAxis4, true)}>
                            {'QR-10\n'}<Text style={styles.mapText}>궁평항</Text>
                        </Text>
                        <Image source={animal_1} style={styles.animal_1}/>
                        <Image source={animal_2} style={styles.animal_2}/>
                        <Image source={animal_3} style={styles.animal_3}/>
                        <Image source={animal_4} style={styles.animal_4}/>
                    </View>
                </ImageBackground>
            </View>

            <View style={styles.bottomWrapper}>
                <Pressable
                    style={styles.qrWrapper}
                    onPress={()=>{
                        if (!closedPlogging || !isEnd)
                            navigation.push('qrPage', {isFirst: isFirst})
                    }}
                >
                    <Image source={qr_icon} style={styles.qr_icon}/>
                    <Text style={styles.qrText}>QR찍기</Text>
                </Pressable>
                <View style={styles.bottomHorizontalWrapper}>
                    <Pressable
                        style={[styles.bottomButtonWrapper,
                            isBack?{backgroundColor:'#EDEDED'}:{backgroundColor:'#469046'}]}
                        onPress={()=>{
                            if (!isBack&&!isFirst)
                                setBackVisible(true)
                        }}
                    >
                        <Text style={[styles.bottomButtonText,isBack&&{color:'#666565'}]}>{'왔던 길\n돌아 가기'}</Text>
                    </Pressable>
                    <Pressable
                        style={[styles.bottomButtonWrapper, {backgroundColor:'#CDE9CD'}]}
                        onPress={()=>{
                            if (!isEnd&&!isFirst)
                                setEndVisible(true)
                        }}
                    >
                        <Text
                            style={[styles.bottomButtonText,
                                {color: '#469046', }]}>
                            {'종료하기'}
                        </Text>
                    </Pressable>
                </View>
            </View>
        </View>
    )
    
}

const styles = StyleSheet.create({
    wrapper: {
        width: '100vw',
        backgroundColor: '#FEFAED',
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(35),
        paddingBottom: PixelRatio.getPixelSizeForLayoutSize(35),
        gap: PixelRatio.getPixelSizeForLayoutSize(25),
    },
    topWrapper: {
        height: PixelRatio.getPixelSizeForLayoutSize(100),
        paddingHorizontal: PixelRatio.getPixelSizeForLayoutSize(70),
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        width: PixelRatio.getPixelSizeForLayoutSize(120),
        height: PixelRatio.getPixelSizeForLayoutSize(41.1),
    },
    helpWrapper: {
        position: 'absolute',
        right: PixelRatio.getPixelSizeForLayoutSize(10),
        height: PixelRatio.getPixelSizeForLayoutSize(25),
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: PixelRatio.getPixelSizeForLayoutSize(1),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(3),
        borderColor: '#666565',
        padding: PixelRatio.getPixelSizeForLayoutSize(4),
    },
    helpText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(14),
        fontWeight: '400',
        lineHeight: '160%'
    },
    contentWrapper: {
        position: 'sticky',
        width: PixelRatio.getPixelSizeForLayoutSize(335),
        height: PixelRatio.getPixelSizeForLayoutSize(499),
        alignItems: 'center',
        justifyContent: 'center'
    },
    roadWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(260),
        height: PixelRatio.getPixelSizeForLayoutSize(456),
    },
    stampLarge: {
        width: PixelRatio.getPixelSizeForLayoutSize(50),
        height: PixelRatio.getPixelSizeForLayoutSize(50),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(25),
        backgroundColor: '#FEFAED',
        zIndex: 1
    },
    stampSmall: {
        width: PixelRatio.getPixelSizeForLayoutSize(20),
        height: PixelRatio.getPixelSizeForLayoutSize(20 ),
    },
    stamp_lAxis1: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(5),
        top: PixelRatio.getPixelSizeForLayoutSize(5),
    },
    stamp_lAxis2: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(205),
        top: PixelRatio.getPixelSizeForLayoutSize(137),
    },
    stamp_lAxis3: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(5),
        top: PixelRatio.getPixelSizeForLayoutSize(269),
    },
    stamp_lAxis4: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(205),
        top: PixelRatio.getPixelSizeForLayoutSize(401),
    },
    stamp_sAxis1: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(90),
        top: PixelRatio.getPixelSizeForLayoutSize(76),
    },
    stamp_sAxis2: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(150),
        top: PixelRatio.getPixelSizeForLayoutSize(96),
    },
    stamp_sAxis3: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(150),
        top: PixelRatio.getPixelSizeForLayoutSize(208),
    },
    stamp_sAxis4: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(90),
        top: PixelRatio.getPixelSizeForLayoutSize(228),
    },
    stamp_sAxis5: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(90),
        top: PixelRatio.getPixelSizeForLayoutSize(340),
    },
    stamp_sAxis6: {
        position: 'absolute',
        left: PixelRatio.getPixelSizeForLayoutSize(150),
        top: PixelRatio.getPixelSizeForLayoutSize(360),
    },
    road_1: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.48),
        left: PixelRatio.getPixelSizeForLayoutSize(25),
        top: PixelRatio.getPixelSizeForLayoutSize(30),
    },
    road_2: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(36.88),
        height: PixelRatio.getPixelSizeForLayoutSize(20.64),
        left: PixelRatio.getPixelSizeForLayoutSize(111.56),
        top: PixelRatio.getPixelSizeForLayoutSize(85.69),
    },
    road_3: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.48),
        left: PixelRatio.getPixelSizeForLayoutSize(171.61),
        top: PixelRatio.getPixelSizeForLayoutSize(106.54),
    },
    road_4: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.47),
        left: PixelRatio.getPixelSizeForLayoutSize(171.61),
        top: PixelRatio.getPixelSizeForLayoutSize(162.01),
    },
    road_5: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(36.85),
        height: PixelRatio.getPixelSizeForLayoutSize(20.62),
        left: PixelRatio.getPixelSizeForLayoutSize(111.57),
        top: PixelRatio.getPixelSizeForLayoutSize(217.71),
    },
    road_6: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.47),
        left: PixelRatio.getPixelSizeForLayoutSize(25),
        top: PixelRatio.getPixelSizeForLayoutSize(238.56),
    },
    road_7: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.47),
        left: PixelRatio.getPixelSizeForLayoutSize(25),
        top: PixelRatio.getPixelSizeForLayoutSize(294.04),
    },
    road_8: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(36.87),
        height: PixelRatio.getPixelSizeForLayoutSize(20.62),
        left: PixelRatio.getPixelSizeForLayoutSize(111.57),
        top: PixelRatio.getPixelSizeForLayoutSize(349.73),
    },
    road_9: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(63.39),
        height: PixelRatio.getPixelSizeForLayoutSize(55.48),
        left: PixelRatio.getPixelSizeForLayoutSize(171.61),
        top: PixelRatio.getPixelSizeForLayoutSize(370.58),
    },
    animal_1: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(42.93),
        height: PixelRatio.getPixelSizeForLayoutSize(70),
        left: PixelRatio.getPixelSizeForLayoutSize(10),
        top: PixelRatio.getPixelSizeForLayoutSize(-28),
        zIndex: 1,
    },
    animal_2: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(41.36),
        height: PixelRatio.getPixelSizeForLayoutSize(70),
        left: PixelRatio.getPixelSizeForLayoutSize(209),
        top: PixelRatio.getPixelSizeForLayoutSize(102),
        zIndex: 1,
    },
    animal_3: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(55.32),
        height: PixelRatio.getPixelSizeForLayoutSize(82.47),
        left: PixelRatio.getPixelSizeForLayoutSize(4),
        top: PixelRatio.getPixelSizeForLayoutSize(225),
        zIndex: 1,
    },
    animal_4: {
        position: 'absolute',
        width: PixelRatio.getPixelSizeForLayoutSize(58.61),
        height: PixelRatio.getPixelSizeForLayoutSize(70),
        left: PixelRatio.getPixelSizeForLayoutSize(207),
        top: PixelRatio.getPixelSizeForLayoutSize(370),
        zIndex: 1,
    },
    bottomWrapper: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    qrWrapper: {
        position: 'relative',
        bottom: PixelRatio.getPixelSizeForLayoutSize(-15),
        width: PixelRatio.getPixelSizeForLayoutSize(100),
        height: PixelRatio.getPixelSizeForLayoutSize(100),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(50),
        backgroundColor: '#DD7451',
        alignItems: 'center',
        justifyContent: 'center',
        gap: PixelRatio.getPixelSizeForLayoutSize(4)

    },
    qr_icon: {
        width: PixelRatio.getPixelSizeForLayoutSize(42),
        height: PixelRatio.getPixelSizeForLayoutSize(42),
    },
    qrText: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(16),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(24),
        textAlign: 'center'
    },
    bottomHorizontalWrapper: {
        flexDirection: 'row',
        gap: PixelRatio.getPixelSizeForLayoutSize(20),
    },
    bottomButtonWrapper: {
        width: PixelRatio.getPixelSizeForLayoutSize(120),
        height: PixelRatio.getPixelSizeForLayoutSize(120),
        borderRadius: PixelRatio.getPixelSizeForLayoutSize(60),
        alignItems: 'center',
        justifyContent: 'center',
        filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.10));'
    },
    bottomButtonText: {
        color: '#FEFAED',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(20),
        fontWeight: '700',
        lineHeight: PixelRatio.getPixelSizeForLayoutSize(25),
        textAlign: 'center'
    },
    mapText: {
        color: '#666565',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(12),
        fontWeight: '700',
    }
})

function getTextAxis(nodeAxis, isBig){
    const r = PixelRatio.getPixelSizeForLayoutSize(isBig?25:10)
    const topMargin = PixelRatio.getPixelSizeForLayoutSize(isBig?30:13)

    const style = {
        width: PixelRatio.roundToNearestPixel(r)*4,
        color: '#B2B2B2',
        fontSize: PixelRatio.getPixelSizeForLayoutSize(10),
        fontWeight: '400',
        position: 'absolute',
        left: isBig? nodeAxis.left - r : nodeAxis.left - r,
        top: nodeAxis.top + r + topMargin,
        textAlign: 'center',
        zIndex: 2,
    }

    return style
}


export default Plogging